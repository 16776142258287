<template>
  <div class="stationView">
    <main class="player-content flex-row">
      <section class="flex-1">
        <div class="flex-autorow flex-top flex-stretch player-channel">

  <div class="flex-item flex-1">
    <div class="push-bottom">
      <h4 class="text-nowrap fx fx-slide-left fx-delay-1">5 Derniers Tubes Diffusés</h4>
    </div>
    <div v-if="!hasSongs" class="card push-bottom">
      There are no songs loaded yet for this station.
    </div>
    <ul v-if="info.length" class="player-tracklist push-bottom">

      <li v-for="( el, i ) of info" :key="el.title"
          :class="'fx-slide-left fx-delay-' + ( i + 2 )"
          class="card fx flex-row flex-top flex-stretch">

        <div><img :alt="el.title" :src="el.cover_url" height="70" width="70"/></div>
        <div class="pad-left">
          <div> <span class="text-secondary">{{ el.title }}</span> </div>
          <div><span class="text-bright">{{ el.artist }}</span> </div>
        </div>

      </li>

    </ul>

  </div>

      </div>
      </section>
    </main>

  </div>
</template>

<script>
import axios from 'axios';
import { mapState  } from 'vuex';
export default {
    data () {
    return {
        info: [], // make this an empty array
    }
},
  name: "songsHistory",
  computed: {
    ...mapState('nowplaying',{
      songs : state => state.songs,
    }),
    config(){
      return this.$store.getters["playerConfig/getConfig"];
    },
    hasSongs: (songs) => {
      return (!!Array(songs).length);
    }
  },

  mounted () {
    axios
      .get('https://ythomas.fr/api_danceradio/api.php?url=https://api.radioking.io/widget/radio/danceradiofr/track/current?format=text&type=RadioKing&pochette_perso=https://www.danceradio.fr/upload/design/6570df341f4a56.94982973.png')
      .then(response => (this.info = response.data.last_title))
},
}
</script>

<style scoped>

</style>