<template>
  <div class="flex-item flex-1">
    <!-- 
    <div class="card push-bottom fx fx-slide-up fx-delay-3">
      <div  class="text-secondary">
        <span class="text-faded">Playlist:</span> {{ currentsong.pochette }}
      </div>
    </div>-->

    <div class="push-bottom ">
      <div class="card flex-item flex-top flex-stretch fx fx-slide-up fx-delay-4 flex-1">

        <div class="track-artwork">
          <img v-if="PochetteEnCours" id="coverArt" :alt="TitreEnCours" :src="PochetteEnCours" class="fx fx-fade-in"/>
          <img v-else id="coverArt" :alt="TitreEnCours" :src="config.cover_default" class="fx fx-fade-in"/>
        </div>
        <div class="pad-bottom current-song">
          <h3 v-if="TitreEnCours" class="text-secondary">{{ TitreEnCours }}</h3>
          <h3 v-else class="text-secondary">{{ config.title }}</h3>
          <h5 v-if="ArtisteEnCours" class="text-bright text-faded">{{ ArtisteEnCours }}</h5>
          <h5 v-else class="text-bright text-faded">{{ config.description }}</h5>
        </div>

      </div>
      <div v-if="NextTitre" class="next-song">
        <div class="card fx flex-row flex-middle flex-space fx-slide-left fx-delay-2">

          <div class="pad-right"> A Suivre :
            <span class="text-secondary">{{ NextTitre }}</span><br>
            <span class="text-bright">   {{ NextArtiste }}</span>

          </div>
          <div class="pad-left"><img :alt="NextTitre" :src="NextPochette"
                                     height="70"
                                     width="70"/></div>
        </div>
      </div>
    </div>
    <!-- buttons 
    <div v-if="config.socialBtn" class="push-bottom text-nowrap">
      <router-link to="/dedicace" class="cta-btn text-nowrap fx fx-slide-up fx-delay-5"  custom v-slot="{ navigate }" >
                        <span class="fx fx-notx fx-ibk fx-drop-in" @click="navigate" @keypress.enter="navigate"><i
                                        class="fa fa-comments"></i></span>
                      </router-link>
      &nbsp;
      <a v-if="currentsong" :href="twitterShare" class="cta-btn text-nowrap fx fx-slide-up fx-delay-6"
         rel="noreferrer" target="_blank" title="Share on twitter">
        <i class="fab fa-twitter"></i>
      </a> &nbsp;
    </div>-->

  </div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex';
import favBtn from "@/views/components/favBtn";


export default {
  name: "mainSong",
  components: {
    favBtn
  },
          data: () => {
            return {
                NextArtiste: "",
                NextTitre: "",
                NextPochette : "",
            }
        },
  methods: {
          test2() {
    axios.get("https://api.radioking.io/widget/radio/danceradiofr/track/next?limit=1")
    .then(response => {
    if (response.data[0].title !== this.NextTitre) {
          this.NextArtiste = response.data[0].artist;
          this.NextTitre = response.data[0].title;
          if(response.data[0].cover_url) {
            this.NextPochette = response.data[0].cover_url;
          }   else {
             this.NextPochette = "img/icon.png" ;
          }
    }
    console.log(response.data[0]);
    })
    setTimeout(this.test2, 12000); 
},
  },

    props: {
    ArtisteEnCours: String,
    TitreEnCours: String,
    PochetteEnCours: String,
  },
  computed: {
    config(){
      return this.$store.getters["playerConfig/getConfig"];
    },
    hasNextSong() {
      if (this.nextSong != null) return true;
      return false;
    },
    twitterShare(){
      return "https://twitter.com/share?text=" + this.$t('tw_listening_to') + this.currentsong.text + this.$t('live_on') + this.config.title + "&url=" + window.location.href ; // + "&hashtags=hashtag1,hashtag2,hashtag3"
    },
  },
          mounted() {

    this.test2();
        },
}
</script>

<style scoped>

</style>